<template>
  <!-- *** LIST VIEW *** -->
  <EuiContainer>
    <EuiGrid gutters>
      <!-- **TOOLBAR** -->
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('templates.title') }}</EuiHeading>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$t('templates.all.title')">
            <EuiGrid>
              <EuiGridRow class="eui-u-justify-center">
                <EuiGridColumn>
                  <EuiHeading :level="4" class="eui-u-mt-1 eui-u-mb-1">{{ $tc('templates.total', totalCount, { count: totalCount }) }}</EuiHeading>
                </EuiGridColumn>
                <EuiGridColumn>
                  <EuiSearchBar v-model="searchInput"
                                :placeholder="$t('searchBar.placeholder')"
                                @change="searchByName"
                                @search="searchByName"
                                @clear="onClear"
                                :historyTitle="$i18n.t('searchBar.history')" clearable />
                </EuiGridColumn>
              </EuiGridRow>
              <EuiGridRow>
                <EuiTooltip :content="$t('button.delete')" placement="top" v-if="selected.length > 0">
                  <template slot="anchor">
                    <EuiButton iconOnly color="primary" @click="deleteMultiple(selected)"><EuiIcon name="delete"/></EuiButton>
                  </template>
                </EuiTooltip>
                <EuiTooltip :content="$t('button.pin')" placement="top" v-if="!isDeskRhDomain && checkIfNotAlreadyPin('select')">
                  <template slot="anchor">
                    <EuiButton iconOnly color="primary" @click="storePinToHomePage(checkIfNotAlreadyPin('select'))"><EuiIcon name="pin"/></EuiButton>
                  </template>
                </EuiTooltip>
              </EuiGridRow>
            </EuiGrid>
            <EuiPagination v-model="currentPage"
                           class="ef-u-mb-20"
                           :displayText="$t('pagination.text')"
                           :totalPages="totalPages"
                           :itemsPerPage="searchParams.limit"
                           @update:itemsPerPage="numberChange($event)"
                           @change="goToPage($event)"/>
            <!-- uncomment this if you need to use SubMenu component
            <FiltersBar secondary
                        :filters='filters'
                        @clear-filters="clearFilters(clearSearchParamsFromFilter, searchTemplates)"
                        @delete-filter="deleteFilterToCompute"
                        @update-filter="updateFilterToCompute"/> -->
            <div v-show="this.filters.length > 0">
              <EuiDivider secondary/>
              <div class="eui-u-flex eui-u-items-center eui-u-ml-6">
                <EuiHeading class='eui-u-text-blue-grey eui-u-my-2' :level="3" bold>{{ $t('filterBar.title')}}</EuiHeading>
                <EuiIcon class='eui-u-text-blue-grey eui-u-ml-2' name='Filter2' size='medium'/>
                <EuiChip v-for="filter in filters"
                         closable
                         @click:close="removeFilter(filter)"
                         :key="filter.name"
                         class="eui-u-text-white eui-u-ml-4">
                  <template #text>
                    <span class="eui-u-font-bold eui-u-text-white">{{ $t(`filterBar.chips.title.${filter.name}`) }}:</span>
                    <span class="eui-u-text-white">{{ filter.value }}</span>
                  </template>
                </EuiChip>
                <EuiChip :text="$t('filterBar.button.reset')"
                         clickable outlined
                         class="eui-u-ml-4"
                         @click:chip="resetFilters"/>
              </div>
            </div>
            <EuiDivider secondary/>
            <!-- **TEMPLATES TABLE** -->
            <EuiAdvancedTable v-if="templates.length" v-model="templates" :selectedValue.sync="selected">
              <template #tableHead>
                <!-- checkbox -->
                <!-- template name USING SUBMENU : uncomment this if you need to add another option to the SubMenu
                <EuiTableHead @onSortClick="onSortClick($event, 'token')">
                  <div class="eui-u-flex">
                    {{ header.name }}
                    <SubMenu :field="header.field"
                             :name="header.name"
                             :filterType="header.filter"
                             :moveColumns="false"
                             @addFilter="addFilter($event, computeFilter, searchTemplates)"/>
                  </div>
                </EuiTableHead> -->
                <EuiTableHead class="pt-0 pb-0">
                  {{ header.name }}
                  <EuiDropdownMenu ref="ddNameFilter" :closeOnClickInside="false">
                    <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                   :buttonText="$t('cardFilter.button.filter')"
                                   v-model="availableFilters.name.searchFieldValue"
                                   @click:search="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                   @enter:searchBar="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                   @click:filter="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()">
                    </EuiCardFilter>
                    <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
                  </EuiDropdownMenu>
                </EuiTableHead>
                <EuiTableHead />
              </template>
                <EuiATableRow selectable="multiple" slot="tableRow" slot-scope="{ item }">
                <!-- name -->
                  <EuiTableCell @click.native="$router.push({ name: 'templates.use.addParameters', params: { templateId: item.id } })">
                    <EuiTooltip :content="$t('templates.icon.pin')" placement="top">
                      <template #anchor>
                        <EuiIcon name="pin" v-if="!checkIfNotAlreadyPin('row', item)" class="eui-u-mr-1 eui-u-text-blue-grey" />{{ item.name }}
                      </template>
                    </EuiTooltip>
                  </EuiTableCell>
                  <EuiTableCell dataType="button">
                    <EuiDropdownMenu>
                      <template v-slot:anchor>
                        <EuiButton iconOnly color="primary" size="small" @click.stop>
                          <EuiIcon name="more_vert"/>
                        </EuiButton>
                      </template>
                      <EuiList>
                        <EuiListItem :button="true" @click.stop="$router.push({ name: 'templates.use.addParameters', params: { templateId: item.id } })">{{ $t('button.use') }}</EuiListItem>
                        <EuiListItem :button="true" v-if="!isDeskRhDomain && checkIfNotAlreadyPin('row', item)" @click.stop="storePinToHomePage(item)">{{ $t('button.pin') }}</EuiListItem>
                        <EuiListItem v-else-if="!isDeskRhDomain" @click.stop="storeDeletePinFromHomePage(item)">{{ $t('button.pin.delete')}}</EuiListItem>
                        <EuiListItem :button="true" v-if="$store.state.ModuleEdocSign.auth.profile.features && $store.state.ModuleEdocSign.auth.profile.features.hasWriteModelPermission === '1'" @click.stop="deleteTemplate(item.id)">
                          {{ $t('button.delete') }}
                        </EuiListItem>
                      </EuiList>
                    </EuiDropdownMenu>
                  </EuiTableCell>
                </EuiATableRow>
            </EuiAdvancedTable>

            <!-- **PAGINATION** -->
            <EuiPagination  v-model="currentPage"
                            class="ef-u-mb-20"
                            :displayText="$t('pagination.text')"
                            :totalPages="totalPages"
                            :itemsPerPage="searchParams.limit"
                            @update:itemsPerPage="numberChange($event)"
                            @change="goToPage($event)"/>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import toCreate from '../../mixins/fromTemplateToCreate';
// import SubMenu from '../../components/table/SubMenu'; uncomment if needed
// import FiltersBar from '../../components/table/FiltersBar'; uncomment if needed
import tableFilters from '../../mixins/tableFilters';

export default {
  components: {
    // SubMenu, uncomment if needed
    // FiltersBar, uncomment if needed
  },
  mixins: [toCreate, tableFilters],
  data() {
    return {
      header: { name: this.$i18n.t('templates.table.title'), field: 'name', filter: ['sortable'] },
      selected: [],
      searchInput: '',
      searchParams: {
        orders: [{ fieldName: 'token', orderBy: 'DESC' }],
        params: [],
        offset: 0,
        limit: 10,
      },
      searchIsOn: false,
      templates: [],
      totalCount: null,
      filters: [],
      availableFilters: {
        name: {type: 'text', name: 'name', value: '', searchParamName: 'token', searchFieldValue: ''},
      },
      currentPage: 1,
      totalPages: 1,
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.templates.pagination,
      pinedToHomePage: (state) => state.ModuleEdocSign.preferences.pinedToHomePage,
      isDeskRhDomain: state => state.ModuleEdocSign.auth.domains.isDeskRh,
    }),
  },
  methods: {
    ...mapActions({
      findAll: 'ModuleEdocSign/templates/findAll',
      search: 'ModuleEdocSign/templates/search',
      destroy: 'ModuleEdocSign/templates/destroy',
      storePinToHomePage: 'ModuleEdocSign/preferences/pinToHomePage',
      storeGetPinToHomePage: 'ModuleEdocSign/preferences/getPinToHomePage',
      storeDeletePinFromHomePage: 'ModuleEdocSign/preferences/deletePinFromHomePage',
    }),
    checkIfNotAlreadyPin(mode, element) {
      if (mode === 'select') {
        if (this.selected.length) {
          const filtered = this.selected.filter((element) => !this.pinedToHomePage.find(item => item.id === element.id));
          if (filtered.length) {
            return filtered;
          }
          return false;
        }
      } else if (mode === 'row') {
        if (this.pinedToHomePage.length) {
          return !this.pinedToHomePage.find((item) => item.id === element.id);
        }
        return true;
      }
    },
    numberChange(number) {
      this.searchParams.limit = number;
      this.searchParams.offset = 0;
      this.currentPage = 1;
      this.searchTemplates();
    },
    computeFilter() {
      this.resetPagination();
      this.filters.forEach((filter) => {
        filter.searchTags.forEach((searchTag, index) => {
          this.searchParams.params[index] = { paramName: 'token', paramValue: searchTag };
        });
        if (filter.sort.length > 0) {
          this.searchParams.orders[0] = {
            fieldName: filter.field,
            orderBy: filter.sort[0],
          };
        }
      });
    },
    deleteFilterToCompute(field) {
      this.deleteFilter(field, this.clearSearchParamsFromFilter, this.searchTemplates);
    },
    updateFilterToCompute(newFilters) {
      this.updateFilter(newFilters, this.computeFilter, this.searchTemplates);
    },
    clearSearchParamsFromFilter(filter) {
      if (filter.sort.length > 0) {
        this.searchParams.order = { field: 'name', sens: 'ASC' };
      }
      this.searchParams.params.splice(this.searchParams.params.find((param) => param.paramValue === filter.searchTags[0]), 1);
    },
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchTemplates();
    },
    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.availableFilters.name.searchFieldValue = '';
      this.addFilter(filter);
    },
    addFilter(filterToAdd) {
      this.filters = this.filters.filter(filter => filter.name !== filterToAdd.name);
      this.filters.push(filterToAdd);
      this.searchOnFilters();
    },
    removeFilter(filterToRemove) {
      this.filters = this.filters.filter(filter => filter.name !== filterToRemove.name);
      this.searchOnFilters();
    },
    resetFilters() {
      this.filters = [];
      this.searchOnFilters();
    },
    searchOnFilters() {
      this.searchParams.orders = [{ fieldName: 'name', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.filters.forEach(filter => {
        this.searchParams.params.push({
          paramName: filter.searchParamName,
          paramValue: filter.value,
        });
      });
      this.searchTemplates(this.searchParams);
    },
    onClear() {
      this.searchParams.orders = [{ fieldName: 'name', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.searchTemplates();
    },
    searchByName() {
      this.searchParams.orders = [{ fieldName: 'name', orderBy: 'asc' }];
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput !== undefined ? this.searchInput : '',
      }];
      this.searchTemplates();
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchTemplates();
    },
    searchTemplates() {
      this.search(this.searchParams).then((response) => {
        this.totalCount = response.nbModels;
        this.templates = response.models;
        this.totalPages = this.pagination.totalPages;
      });
    },

    /**
     * Method used to go to the edit template page.
     *
     * @param {String} id The template identifier.
     *
     */
    goToEditPage(id) {
      this.$router.push(
        {
          name: 'templates.edit',
          params: { templateId: id },
        },
      );
    },

    fetchTemplates() {
      this.searchParams.limit = this.pagination.limit;
      return this.findAll(this.searchParams).then((response) => {
        this.totalCount = response.nbModels;
        this.templates = response.models;
        this.totalPages = this.pagination.totalPages;
      });
    },
    /**
     * Method used to delete template.
     *
     */
    deleteTemplate(templateId) {
      this.destroy(templateId).then(() => {
        this.$store.dispatch('application/pushAlert', {
          message: this.$tc('store.templates.destroy.success', 1),
          type: 'success',
        });
        this.fetchTemplates();
      });
    },
    deleteMultiple(list) {
      const promises = [];
      for (let i = 0; i < list.length; i++) {
        promises.push(this.destroy(list[i].id));
      }
      Promise.all(promises).then(() => {
        this.$store.dispatch('application/pushAlert', {
          message: this.$tc('store.templates.destroy.success', promises.length),
          type: 'success',
        });
        this.fetchTemplates();
        this.selected = [];
      });
    },
  },
  watch: {
    $route() {
      this.selected = [];
    },
  },
  /**
   * This method will be fired once the page has been loaded.
   * It'll fetch the organization using the given template identifier.
   */
  mounted() {
    this.fetchTemplates();
    this.storeGetPinToHomePage();
  },
};
</script>
