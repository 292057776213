export default {
  methods: {
    useTemplate(template) {
      // send to creation
      this.$router.push({
        name: 'createSignworkflow',
        params: {
          template: this.transformOnFetch(template),
        },
      });
    },
    transformOnFetch(template) {
      const paraph = {
        name: '',
        endingDate: '',
        notificationEmail: template.notificationEmail ? template.notificationEmail : undefined,
        globalMessage: template.globalMessage,
        allowDecline: template.allowDecline === '1',
        allowFirstThenSimultaneousSteps: template.allowFirstThenSimultaneousSteps === '1',
        allowSimultaneousSteps: template.allowSimultaneousSteps === '1',
        sendToCustomerOnEdocFactory: template.sendToCustomerOnEdocFactory === '1',
        alertConfiguration: {
          frequency: parseInt(template.alertFrequency),
          intervall: parseInt(template.alertIntervall) / (60 * 60 * 24),
        },
        documents: template.documents,
        steps: [],
        signatories: [],
        recipientOrganizations: [],
        thirdPartiesEmails: [],
      };
      // set global deadline
      let endingDate = new Date();
      endingDate = new Date(endingDate.setHours(23, 59, 59));
      endingDate = new Date(endingDate.setDate(endingDate.getDate() + parseInt(template.duration)));
      paraph.endingDate = this.formatDate(endingDate);

      const dates = [];
      // set each steps from template
      template.steps.forEach((item, index) => {
        const docIdsToSign = [...new Set(JSON.parse(item.signatureParams).map((item) => item.docId))];
        paraph.steps[index] = {
          id: index,
          userId: undefined,
          docIdsToSign,
          date: '',
          comment: '',
          signerOnlyValidate: item.signerOnlyValidate === '1',
          signatureParams: {
            // applyToAllDocuments: docIdsToSign.length === paraph.documents.length,
            positions: JSON.parse(item.signatureParams),
          },
        };
        const signatory = {
          type: item.userType === 'external' ? 'externalSignatory' : item.userType === 'internal' ? 'internalUser' : item.userType === 'social' ? 'socialSignatory' : undefined,
          signerOnlyValidate: item.signerOnlyValidate === '1',
        };
        if (item.duration && parseInt(item.duration) > 0) {
          let stepDate = new Date();
          stepDate = new Date(stepDate.setHours(23, 59, 59));
          stepDate = new Date(stepDate.setDate(stepDate.getDate() + parseInt(item.duration)));
          signatory.date = this.formatDate(stepDate);
          paraph.steps[index].date = signatory.date;
        }
        paraph.signatories.push(signatory);

        // set steps' deadline dynamically
        let date;
        if (index === 0) {
          date = new Date();
          date = new Date(date.setHours(23, 59, 59));
        } else {
          date = new Date(dates[index - 1]);
        }
        dates.push(new Date(date.setDate(date.getDate() + parseInt(item.duration))));
      });

      for (let index = 0; index < paraph.documents.length; index++) {
        paraph.documents[index].type = {
          typeCode: paraph.documents[index].typeCode,
          typeDescription: paraph.documents[index].typeDescription,
        };
      }
      paraph.steps.forEach((item, index) => {
        // item.signatureParams.positions.forEach(item => { item.docId = ''; });
        item.date = this.formatDate(dates[index]);
      });
      return { item: paraph, nbMinDocs: template.nbMinDocs };
    },
    formatDate(date) {
      const d = new Date(date);
      let day = `${d.getDate()}`;
      let month = `${d.getMonth() + 1}`;
      const year = d.getFullYear();

      day = day.length < 2 ? `0${day}` : day;
      month = month.length < 2 ? `0${month}` : month;

      return [year, month, day].join('-');
    },
  },
};
